<template>
  <div class="add-ticket">
    <p class="info Q-font">
      <i class="bx bx-info-circle bx-tada"></i>Fill the form fields according to
      your choice and submit to send us.
    </p>
    <form @submit.prevent="submitAddTicket">
      <Input
        label="Title"
        v-model="title"
        :validation="['required']"
        @isValid="titleIsValid"
        :checkValidationFlag="checkValidationFlag"
        type="text"
        icon="bx bx-label bx-tada"
      ></Input>
      <DropDown
        class="dp"
        :selectableItems="selectableItems"
        :required="true"
        :checkValidationFlag="checkValidationFlag"
        v-model="department"
        @isValid="departmentIsValid"
        place-holder="Department Select"
      ></DropDown>
      <Textarea
        class="input"
        label="message"
        v-model="message"
        type="text"
        icon="bx bx-message-detail bx-tada"
      ></Textarea>
      <PanelBaseButton type="submit">
          <Loading class="loading" v-if="loading"></Loading>
        <div style="display: flex; justify-content:center; align-items: center" v-else>
          Submit
        </div>
      </PanelBaseButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      title: null,
      titleValidation: null,
      message: null,
      department: null,
      departmentValidation: null,
      checkValidationFlag: false,
      loading: false,
      selectableItems: [
        { name: "support", value: "support" },
        { name: "technical ", value: "technical " },
        { name: "sales ", value: "sales " },
        { name: "financial ", value: "financial " },
      ],
    };
  },
  methods: {
    ...mapActions(["addTicket"]),
    titleIsValid(v) {
      this.titleValidation = v;
    },
    departmentIsValid(v) {
      this.departmentValidation = v;
    },
    submitAddTicket() {
      if (this.titleValidation && this.departmentValidation) {
        const info = {
          title: this.title,
          department: this.department,
          message: this.message,
        };
        this.loading = true;
        this.addTicket(info)
          .then((res) => {
            // 
          })
          .catch(() => {})
          .finally(() => {
            this.$emit("addSubmited");
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-ticket {
  padding: 20px;
  background: linear-gradient(200deg, var(--color3), var(--home-bg));
  .info {
    color: var(--color6);
    font-size: 13px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  .dp {
    margin: 0;
    margin-bottom: 30px;
  }
  .input {
    :v-deep input {
      height: 80px;
    }
  }
 
}
</style>
