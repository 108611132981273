<template>
  <div class="chats">
    <div
      class="chat"
      v-for="(message, i) in messages"
      :key="i"
      :class="message.user_type !== 'client' ? 'server' : ''"
    >
      <span v-if="message.user_type === 'client'">
        <i
          class="bx bx-check-double"
          style="color: blue"
          v-if="message.read"
        ></i>
        <i class="bx bx-check" v-else></i>
      </span>
      <p class="Q-font">{{ message.message }}</p>
      <span class="time">{{handleShowTime(message.created_at)}}</span>
    </div>
    <div class="enter-message">
      <form @submit.prevent="sendMessage">
        <input
          type="text"
          v-model="enteredMessage"
          placeholder="message"
          class="Q-font"
        />
        <button type="submit" :disabled="!enteredMessage">
          <i class="bx bxs-send" :class="enteredMessage ? 'active' : ''"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["messages", "ticketId"],
  data() {
    return {
      // messages:[{
      //           id: 1,
      //           ticket_id: 1,
      //           user_type: 'client',
      //           read: 0,
      //           message: 'message not required',
      //           created_at: '2022-09-29T06:38:34.000000Z',
      //           updated_at: '2022-09-29T06:38:34.000000Z'
      //       },
      //       {
      //           id: 2,
      //           ticket_id: 1,
      //           user_type: 'server',
      //           read: 0,
      //           message: 'this is new message answer me pleasefdasfew efwfsf fewfsd',
      //           created_at: '2022-09-29T06:41:24.000000Z',
      //           updated_at: '2022-09-29T06:41:24.000000Z'
      //       },
      //       {
      //           id: 4,
      //           ticket_id: 1,
      //           user_type: 'client',
      //           read: 1,
      //           message: 'this is new message answer me please',
      //           created_at: '2022-10-03T07:07:09.000000Z',
      //           updated_at: '2022-10-03T07:07:09.000000Z'
      //       },
      //       {
      //           id: 4,
      //           ticket_id: 1,
      //           user_type: 'client',
      //           read: 1,
      //           message: 'this is new message answer me please',
      //           created_at: '2022-10-03T07:07:09.000000Z',
      //           updated_at: '2022-10-03T07:07:09.000000Z'
      //       },

      //       ],
      enteredMessage: "",
    };
  },
  methods: {
    ...mapActions(["sendMessageTicket"]),
    sendMessage() {
      let info = {
        id: this.ticketId,
        message: this.enteredMessage,
      };
      this.sendMessageTicket(info)
        .then((res) => {
          
        })
        .catch((err) => {
          
        }).finally(()=>{
          this.$emit('fetchAgain')
        })
    },
    handleShowTime(date){
      let dInstance = new Date(date);
      return `${dInstance.getHours()}:${dInstance.getMinutes()}`
    }
  },
};
</script>

<style lang="scss" scoped>
.chats {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  background: var(--color3);
  .chat {
    width: fit-content;
    display: flex;
    align-items: end;
    background: grey;
    padding: 2px 8px 2px 6px;
    margin: 4px 0;
    border-radius: 10px 10px 10px 0;
    color: var(--color4);
    font-size: 15px;
    i {
      margin-right: 2px;
    }
    &.server {
      background: rgb(32, 202, 32);
      border-radius: 10px 10px 0 10px;
      margin-left: auto;
    }
    .time{
      font-size: 10px;
      margin:0 8px;
      color: black;
    }
  }
  .enter-message {
    margin-top: 5px;
    form {
      display: flex;
      align-items: center;

      input {
        width: 90%;
        padding: 4px 8px;
        border-radius: 20px;
        outline: none;
        border: 2px solid rgb(0, 0, 0);
        margin-right: 5px;
      }
      button {
        background: transparent;
        i {
          padding: 8px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: grey;
          color: white;
          &.active {
            background: rgb(63, 134, 63);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
