<template>
  <div class="table">
    <div  class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <NoResult v-if="!tickets || (!tickets.length && !loading)" class="fit-height"></NoResult>
          <li class="headline" v-else>
            <div class="name">tickets</div>
            <div class="date center">Date</div>
            <div class="price center">Status</div>
            <div class="center">Department</div>
          </li>
          <TicketItem
            v-for="(ticket, i) in tickets"
            :key="i"
            :ticket="ticket"
            @clickedItem="clickedItem"
          ></TicketItem>
          <li class="add-item "><PanelBaseButton @click="addTicket" class="lt-hover"><span>Add new item</span> <i class='bx bx-plus-medical' ></i></PanelBaseButton></li>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>
    <!-- chat modal -->
    <BaseModal :dialog="chatDialog" @close="chatDialog = false" title="Chat with us">
      <TicketChat :messages="messages" :ticketId="ticketId" @fetchAgain="fetchMessagesAgain"></TicketChat>
    </BaseModal>
    <!-- chat modal -->

    <!-- add modal -->
    <BaseModal :dialog="addDialog" @close="addDialog = false" title="Add new Item">
     <AddTicket @addSubmited="addSubmited"></AddTicket>
    </BaseModal>
    <!-- add modal -->
  </div>
</template>

<script>
import TicketItem from "./TicketItem.vue";
import TicketChat from "./TicketChat.vue";
import AddTicket from "./AddTicket.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      tickets: [
        // {
        //         id: 1,
        //         user_id: 207,
        //         support_user_id: null,
        //         title: 'ticket title',
        //         department: 'support',
        //         status: 'pending',
        //         created_at: '2022-09-29T06:38:34.000000Z',
        //         updated_at: '2022-09-29T06:38:34.000000Z'
        //     },
        //     {
        //         id: 2,
        //         user_id: 207,
        //         support_user_id: null,
        //         title: 'saeed ticket',
        //         department: 'sales',
        //         status: 'pending',
        //         created_at: '2022-10-02T07:53:32.000000Z',
        //         updated_at: '2022-10-02T07:53:32.000000Z'
        //     }
      ],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
      //chat dialog
      chatDialog: false,
      messages:[],
      ticketId:0,
      //chat dialog

      //add dialog
      addDialog:false,
      //add dialog
    };
  },
  components: { TicketItem, TicketChat,AddTicket },
  created() {
    this.fetchAllTickets();
  },
  methods: {
    ...mapActions(["getAllTickets","getMessagesTicket"]),
    fetchAllTickets() {
      let params = new URLSearchParams({ page: this.page, per_page: 7 });
      this.loading = true;
      this.getAllTickets(params).then((res) => {
        this.tickets = res.data.result.data;
        this.total = res.data.result.total;
        this.lastPage = res.data.result.last_page;
        this.loading = false;
      });
    },
    changePage(val) {
      this.page = val;
      this.fetchAllTickets();
    },
    clickedItem(ticket) {
      // 
      this.getMessagesTicket(ticket.id).then((result)=>{
        this.messages = result.data.result.data;
        this.ticketId = ticket.id;
      })
      this.chatDialog = !this.chatDialog;
    },
    fetchMessagesAgain(){
      this.getMessagesTicket(this.ticketId).then((result)=>{
        this.messages = result.data.result.data;
      })
    },
    addTicket(){
      this.addDialog = true
    },
    addSubmited(){
      this.addDialog = false;
      this.fetchAllTickets();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
.headline {
  @include md {
    display: none !important;
  }
}
.add-item{
  display: flex;
  
    i{
      font-size: 14px;
      margin-left: 20px;
    }
    
}
.fit-height{
  height: 40vh;
}
</style>
