<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">Tickets</h2>

        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in trTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              <i :class="`bx ${tab.icon}`"></i>
              {{ tab.name }}
              <span class="counts">({{ tab.count }})</span>
            </li>
          </ul>
        </div>
      </div>
      <AllTickets v-if="trTabs[0].isActive" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AllTickets from "@/components/panelSections/tickets/AllTickets.vue";
export default {
  data() {
    return {
      trTabs: [
        {
          name: "All",
          icon: "bx-list-check",
          isActive: true,
          count: 0,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    AllTickets,
  },
  computed: {},
  watch: {},
  created() {
    //get session tab name and find it and go on that tab
    if (sessionStorage.getItem("last-ticket-tab")) {
      this.previousTab = this.trTabs.find(
        (element) => element.name === sessionStorage.getItem("last-ticket-tab")
      );
      this.changeTabs(this.previousTab);
    }
    this.fetchAllTickets();
  },
  methods: {
    ...mapActions(["getAllTickets"]),
    fetchAllTickets() {
      this.getAllTickets().then((res) => {
        
        this.trTabs[0].count = res.data.result.total ? res.data.result.total : 0;
      });
    },
    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.trTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.trTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      this.handleSaveTab();
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.trTabs.find((element) => element.isActive === true);
      sessionStorage.setItem("last-ticket-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
</style>
