<template>
  <li class="tournametn-item" @click="clickedItem(ticket)">
    <i class="bx bxs-chat"></i>
    <h3 class="name">{{ ticket.title }}</h3>
    <div class="date center Q-font">
      {{ convertDate(ticket.created_at, "DMMMYYYY") }}
      <!-- -
      {{ dateFormater(ticket.finish_time) }} -->
    </div>
    <div class="status center">
      {{ ticket.status }}
    </div>
    <div class="department center">
      {{ ticket.department }}
    </div>
  </li>
</template>

<script>
import { remainingTime } from "../../../mixins/calendarFormater";

export default {
  props: ["ticket"],
  mixins: [remainingTime],
  data() {
    return {};
  },
  methods: {
    clickedItem(item) {
      this.$emit("clickedItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.tournametn-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 5px;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 5% 36% 19% 15% 15%;
  transition: all 0.1s linear;
  cursor: pointer;
  @include md{
    font-size: 10px;
    grid-template-columns: 8% 30% 25% 20% 20%;
    padding:8px 2px;
  }
  
  &:hover {
    transform: scale(1.02);
    box-shadow: 0.3125em 0.3125em 0.625em black;
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .bxs-chat {
    color: var(--color5);
    font-size: 1.875em;
  }
  .name {
    color: #e8eaf2;
    @include sm{
      font-size: 9px;
      margin: 0 2px;
    }
  }
  div {
    color: #8799e0;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
    @include sm{
      font-size: 6px;
    }
  }
  .status {
    @include sm{
      font-size: 6px;
        margin: 0 4px;
    }
  }
  .department {
    @include sm{
      font-size: 6px;
    }
  }
}
</style>
